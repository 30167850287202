import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const LinkEmail = makeShortcode("LinkEmail");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul"><strong parentName="li">{`Monty McDonald`}</strong>{`, President `}{`&`}{` CEO`}<ul parentName="li">
          <li parentName="ul"><LinkEmail email="monty.mcdonald@hotmail.com" mdxType="LinkEmail" /></li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Ralph Coleman`}</strong>{`, Director Government, Media, and Public Relations`}<ul parentName="li">
          <li parentName="ul"><LinkEmail email="ralph.c.coleman@gmail.com" mdxType="LinkEmail" /></li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Bob Domoney`}</strong>{`, VP Tree Sales`}<ul parentName="li">
          <li parentName="ul"><LinkEmail email="vimyoakscanada@rogers.com" mdxType="LinkEmail" /></li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      